import {
  getProvinceShortName,
} from '../../client/components/common/search/helpers/search';


export const mergeFilters = (seo_filters, qs_filters) => {
  const params = Object.assign({}, seo_filters, qs_filters);
  if (params.portal_immotype_group && !Array.isArray(params.portal_immotype_group)) {
    params.portal_immotype_group = [params.portal_immotype_group];
  }
  return params;
};

export const apiParamsToQueryObject = (filters) => {
  const ret = {};
  if (filters['transaction.type']) {
    ret.tr = filters['transaction.type'];
  }
  if (filters.category) {
    ret.category = filters.category;
  }
  if (filters.property_type_group) {
    ret.propertyTypeGroup = filters.property_type_group;
  }
  if (filters['property.types']) {
    ret.propertyTypes = filters['property.types'].join(',');
  }
  if (filters.price) {
    if (filters.price.gte) { ret.priceMin = filters.price.gte; }
    if (filters.price.lte) { ret.priceMax = filters.price.lte; }
  }
  if (filters.mqprice) {
    if (filters.mqprice.gte) { ret.mqpriceMin = filters.mqprice.gte; }
    if (filters.mqprice.lte) { ret.mqpriceMax = filters.mqprice.lte; }
  }
  if (filters.rooms) {
    const { gte, lte } = filters.rooms;
    if (gte) {
      ret.numRoomsMin = gte;
    }
    if (lte) {
      ret.numRoomsMax = lte;
    }
  }
  if (filters.payment) {
    if (filters.payment.gte) { ret.paymentMin = filters.payment.gte; }
    if (filters.payment.lte) { ret.paymentMax = filters.payment.lte; }
  }
  if (filters.building_year) {
    if (filters.building_year.gte) { ret.buildingYearMin = filters.building_year.gte; }
    if (filters.building_year.lte) { ret.buildingYearMax = filters.building_year.lte; }
  }
  if (filters.only_with_photos) {
    ret.photo = filters.only_with_photos;
  }
  if (filters['garden.type']) {
    // ret.garden = filters['garden.type'].replace(/giardino-/g, 'giardino-giardino');
    ret.garden = filters['garden.type'];
  }
  if (filters['heating.types']) {
    ret.heatingType = filters['heating.types'];
  }
  if (filters.carparks) {
    if (filters.carparks.gte) { ret.numParkingSpaces = filters.carparks.gte; }
  }
  if (filters.lift) {
    ret.lift = filters.lift.toString();
  }
  if (filters.lift_level) {
    ret.lift_level = filters.lift_level.toString();
  }
  if (filters.balcony) {
    ret.balcony = filters.balcony;
  }
  if (filters.terrace) {
    ret.terrace = filters.terrace;
  }
  if (filters.publisher) {
    ret.sellerType = filters.publisher;
  }
  if (filters.sellerType) {
    ret.sellerType = filters.sellerType;
  }
  if (filters.surface) {
    if (filters.surface.gte) { ret.mqMin = filters.surface.gte; }
    if (filters.surface.lte) { ret.mqMax = filters.surface.lte; }
  }
  if (filters.mq) {
    if (filters.mq.gte) { ret.mqMin = filters.mq.gte; }
    if (filters.mq.lte) { ret.mqMax = filters.mq.lte; }
  }

  if (filters.bedrooms) {
    ret.bedrooms_min = filters.bedrooms.gte;
    ret.bedrooms_max = filters.bedrooms.lte;
  }

  if (filters.sortType) {
    ret.sortType = filters.sortType.replace(/-/g, '_');
  }

  if (filters['publisher.id']) {
    ret.pId = filters['publisher.id'];
  }

  if (filters.buildingCondition) {
    ret.buildingCondition = filters.buildingCondition;
  }

  if (filters.page) {
    ret.page = filters.page;
  }

  if (filters.zone) {
    ret.zones = filters.zone.join(',');
  }

  /*
  if (!('surrounding' in filters) || (filters.surrounding && filters.surrounding === 'true')) {
    ret.surrounding = true;
  } else {
    ret.surrounding = false;
  }
  */

  if (filters.isRoomsNumber === true) {
    ret.isRoomsNumber = filters.isRoomsNumber;
  }

  if ('surrounding' in filters) {
    ret.surrounding = filters.surrounding;
  }

  if (filters.poi) {
    ret.poi = filters.poi;
  }
  if (filters.exclude_auction) {
    ret.exclude_auction = filters.exclude_auction;
  }
  if (filters.only_auction) {
    ret.only_auction = filters.only_auction;
  }
  if (filters.has_swimming_pool) {
    ret.has_swimming_pool = filters.has_swimming_pool;
  }
  if (filters.availability && filters.availability === 'nuda-proprieta') {
    ret.availability = 'nuda proprieta';
  }
  if (filters.has_reception) {
    ret.has_reception = filters.has_reception;
  }
  if (filters.furniture) {
    ret.furniture = filters.furniture;
  }
  /* TRESPASOS */
  if (filters.license_type_groups) {
    ret.license_type_groups = filters.license_type_groups.join(',');
  }
  /* TRESPASOS */

  return ret;
};

export const normalizeSRPUrl = (computedUrl, publisherRegion = false) => {
  let uri = computedUrl.replace(new RegExp('[àáâãäå]', 'g'), 'a');
  uri = uri.replace(new RegExp('[èéêë]', 'g'), 'e');
  uri = uri.replace(new RegExp('[òóôõö]', 'g'), 'o');
  uri = uri.replace(new RegExp('[ìíîï]', 'g'), 'i');
  uri = uri.replace(new RegExp('[ùúûü]', 'g'), 'u');
  uri = uri.replace(/'/g, ' ');
  uri = uri.replace(/,/g, '%2C');
  if (publisherRegion) {
    uri = uri.replace(/ /g, '%20');
  } else {
    uri = uri.replace(/ /g, '+');
  }
  uri = uri.replace(/\+%2C/gi, '%2C');
  return uri;
};

export const formatV1Locations = (locations) => {
  const formatted = [];
  locations.forEach(
    (loc) => {
      let displayValue;
      let key;
      let locality;
      let value;
      let withZones;
      switch (loc.level) {
        case 10:
          displayValue = `${loc.name.toLowerCase().replace(/\//g, '+').replace(/,/g, '+')} +${loc.parent.town.name.toLowerCase()},+${loc.parent.province.short.toLowerCase()},+${loc.parent.region.name.toLowerCase()}`;
          key = `${loc.name.toLowerCase().replace(/\//g, '+').replace(/,/g, '+')},+${loc.parent.town.name.toLowerCase()},+${loc.parent.province.short.toLowerCase()},+${loc.parent.region.name.toLowerCase()}`;
          locality = `${loc.name.toLowerCase().replace(/\//g, ',')}, ${loc.parent.town.name.toLowerCase()}, ${loc.parent.province.short.toLowerCase()}, ${loc.parent.region.name.toLowerCase()}`;
          value = 112;
          withZones = false;
          break;
        case 9:
          displayValue = `${loc.name.toLowerCase()} +${loc.parent.province.short.toLowerCase()},+${loc.parent.region.name.toLowerCase()}`;
          key = `${loc.name.toLowerCase()},+${loc.parent.province.short.toLowerCase()},+${loc.parent.region.name.toLowerCase()}`;
          locality = `${loc.name.toLowerCase()}, ${loc.parent.province.short.toLowerCase()}, ${loc.parent.region.name.toLowerCase()}`;
          value = 112;
          withZones = false;
          break;
        case 6:
          displayValue = `${getProvinceShortName(loc.name).toLowerCase()} ,+${loc.parent.region.name.toLowerCase()}`;
          key = `${getProvinceShortName(loc.name).toLowerCase()},+${loc.parent.region.name.toLowerCase()}`;
          locality = `${getProvinceShortName(loc.name).toLowerCase()}, ${loc.parent.region.name.toLowerCase()}`;
          value = 112;
          withZones = false;
          break;
        default:
      }
      formatted.push({
        key,
        value,
        locality,
        displayValue,
        withZones,
      });
    },
  );
  return formatted;
};
